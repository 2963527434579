import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import { HTMLContent } from '../components/Content';

export default ({ data, location }) => {
  const { markdownRemark: page, site } = data;

  return (
    <Layout location={location}>
      <article className="page">
        <Helmet
          title={`${site.siteMetadata.title} | ${page.frontmatter.title}`}
        />

        <h2 className="page__title">{page.frontmatter.title}</h2>
        <section className="page__text">
          <HTMLContent content={page.html} />
        </section>
      </article>
    </Layout>
  );
};

export const pageQuery = graphql`
  query PageQuery($path: String!) {
    markdownRemark(frontmatter: { urlPath: { eq: $path } }) {
      html
      frontmatter {
        title
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }
`;
